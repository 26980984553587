<template>
  <div class="grid grid-cols-1 p-5 bg-white">
  <vue-masonry obra="proyecto" :datos="info.proyectos" :tipo="proyectos" />
  </div>
</template>

<script>
import Masonry from "@/components/Masonry";
import { mapActions, mapState } from "vuex";

export default {
  name: "Proyectos",
  props: {
    obra: String
  },
  components: {
    "vue-masonry": Masonry
  },
  computed: {
    ...mapState(["info"])
  },
  methods: {
    ...mapActions(["getInfo"])
  },
  mounted() {
    this.getInfo()
  }
};
</script>


